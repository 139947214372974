
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomCascader, { CascaderOptions } from '@/modules/common/components/ui-kit/custom-cascader.vue';
import type UserService from '@/modules/user/user.service';
import { BRAND_AS_BROKER_ANY, BROKER_TO_BRAND, BROKER_TO_BROKER } from '@/modules/cars/constants/car-filter-types.constant';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect, CustomCascader },
})
export default class DataSourceBrokerFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(KEY.UserService) private userService!: UserService;
    private modes: Record<string, string> = { Brand: BROKER_TO_BRAND, Broker: BROKER_TO_BROKER };

    created() {
        this.adjustOptionsForEmptyBrandList();
    }

    get options(): CascaderOptions[] {
        const { currentChainCode } = this.carsFiltersService;
        const { allBrandsAsBroker } = this.carsService;
        const anyBrand: CascaderOptions = {
            label: `All ${currentChainCode} brands`,
            value: BRAND_AS_BROKER_ANY,
        };

        return Object.keys(this.modes).map(key => {
            const mode = {
                label: key,
                value: this.modes[key],
                items: [anyBrand, ...allBrandsAsBroker.map(brand => {
                    const country: CascaderOptions = {
                        label: this.renameBroker(brand),
                        value: brand,
                    };
                    return country;
                })],
            };
            return mode;
        });
    }

    get currentValue() {
        const { currentDataSourceMode, currentBrandAsBroker } = this.carsService.storeState.settings;
        return [currentDataSourceMode, currentBrandAsBroker];
    }

    set currentValue(value) {
        if (value) {
            const [currentDataSourceMode, currentBrandAsBroker] = value;
            this.carsService.storeState.settings.currentBrandAsBroker = currentBrandAsBroker || BRAND_AS_BROKER_ANY;
            this.carsService.storeState.settings.currentDataSourceMode = currentDataSourceMode;
        }
    }

    renameBroker(brand: string) {
        const { chainName } = this.userService;
        const { currentChainCode } = this.carsFiltersService;
        if (chainName && currentChainCode) {
            const [, newBrand] = brand.split(',');
            return `${currentChainCode} ${newBrand}`;
        }
        return '';
    }

    get label() {
        return this.$t('cars.dataSource');
    }

    get selectText() {
        const { currentDataSourceMode, currentBrandAsBroker } = this.carsService.storeState.settings;
        return Object.keys(this.modes).reduce((prev, curr) => {
            const [, brand] = currentBrandAsBroker.split(',');
            if (this.modes[curr] === currentDataSourceMode) {
                if (currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
                    return `${curr} (All)`;
                }
                return `${curr} (${brand})`;
            }
            return prev;
        }, '');
    }

    private adjustOptionsForEmptyBrandList(): void {
        if (!this.carsFiltersService.allowedBrands.length) {
            this.modes = { Broker: BROKER_TO_BROKER };
            this.currentValue = [BROKER_TO_BROKER, BRAND_AS_BROKER_ANY];
        }
    }
}
